import React, {
    FormEvent,
    KeyboardEvent,
    MouseEvent,
    PropsWithChildren,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { createAutocomplete, OnSelectParams, OnSubmitParams } from '@algolia/autocomplete-core';
import { useAlgoliaConnector, useFranchise, useTranslation } from '~/shared/utils';
import { AutocompleteState, getAlgoliaResults } from '@algolia/autocomplete-js';
import { AutoSuggestion } from '~/lib/data-contract';
import { AutoCompleteContext, AutocompleteContextType } from './context/AutoCompleteContext';
import { useSearchRouting } from '~/features/navigation';

export type Props = PropsWithChildren<{
    hitsPerPage?: number;
    highlightPreTag?: string;
    highlightPostTag?: string;
    index?: string;
    queryMinLength?: number;
}> &
    Pick<AutocompleteContextType, 'ariaLabelId' | 'placeholder' | 'keywords'>;

const requiredValue = (value?: string, key?: string) => {
    if (!value) {
        console.error(`
            Missing critical value(s), cannot setup autocomplete, aborted trying to use '${key}'
            Make sure Algolia 'appKey', 'apiToken' and 'algoliaSuggestIndex' is available
        `);
        return '';
    }

    return value;
};

/**
 * Handles AutoComplete instance and provide getters to children
 * must always be used as top level component in AutoComplete context
 */
export const AutoComplete = ({
    children,
    hitsPerPage = 4,
    highlightPreTag = '<mark>',
    highlightPostTag = '</mark>',
    ariaLabelId,
    index,
    placeholder,
    keywords,
    queryMinLength = 3,
}: Props) => {
    const { translate } = useTranslation();
    const { submitToSearch } = useSearchRouting();
    const [autoCompleteState, setAutoCompleteState] = useState<AutocompleteState<AutoSuggestion>>();
    const [selectedParams, setSelectedParams] = useState<OnSelectParams<AutoSuggestion>>();
    const algoliaClient = useAlgoliaConnector();

    const { activeMarket } = useFranchise();
    const indexName = requiredValue(
        index || activeMarket?.algoliaSuggestIndex,
        'algoliaSuggestIndex'
    );

    const onSubmit = useCallback(
        (event: OnSubmitParams<AutoSuggestion>) => {
            if (event.state.query) {
                submitToSearch(event.state.query);
            }
        },
        [submitToSearch]
    );

    const params = useMemo(
        () => ({
            hitsPerPage,
            highlightPreTag,
            highlightPostTag,
        }),
        [highlightPostTag, highlightPreTag, hitsPerPage]
    );

    const autocomplete = useMemo(
        () =>
            createAutocomplete<AutoSuggestion, FormEvent, MouseEvent, KeyboardEvent>({
                onStateChange: ({ state }) => {
                    setAutoCompleteState(state as AutocompleteState<AutoSuggestion>);
                },
                getSources: () => [
                    {
                        sourceId: 'querySuggestions',
                        getItemInputValue: ({ item }) => item.query,
                        getItems({ query }) {
                            if (query.length >= queryMinLength) {
                                return getAlgoliaResults({
                                    searchClient: algoliaClient,
                                    queries: [
                                        {
                                            indexName,
                                            query,
                                            params,
                                        },
                                    ],
                                });
                            }
                            return [];
                        },
                        onSelect: (params) => setSelectedParams(params),
                    },
                ],
                placeholder: placeholder ? '' : translate('header.search.placeholder'),
                onSubmit: onSubmit,
            }),
        [placeholder, translate, onSubmit, queryMinLength, algoliaClient, indexName, params]
    );

    const value = useMemo(
        () => ({
            autocomplete,
            state: autoCompleteState,
            selectedParams,
            ariaLabelId,
            placeholder,
            keywords,
        }),
        [ariaLabelId, autoCompleteState, autocomplete, selectedParams, placeholder, keywords]
    );

    return <AutoCompleteContext.Provider value={value}>{children}</AutoCompleteContext.Provider>;
};
